import { useWatch } from 'react-hook-form';

export const useOfficeInformation = (control) => {
  const watchedValues = useWatch({
    control,
    name: [
      'timePerPatientRDH',
      'timePerPatientDA',
      'timePerPatientDN',
      'isAssistedHygiene',
      'productivity',
      'partnershipOrganization',
      'partnershipCode',
    ],
  });

  const formValues = {
    timePerPatientRDH: watchedValues[0],
    timePerPatientDA: watchedValues[1],
    timePerPatientDN: watchedValues[2],
    isAssistedHygiene: watchedValues[3],
    productivity: watchedValues[4],
    partnershipOrganization: watchedValues[5],
    partnershipCode: watchedValues[6],
  };

  const timeFields = ['timePerPatientRDH', 'timePerPatientDA', 'timePerPatientDN'];

  const isTimerPerPatientValid =
    timeFields.every((key) => formValues[key] !== '') && // Ensure no field is empty
    timeFields.some((key) => formValues[key] !== 'N/A'); // At least one field is not 'N/A'

  const isProductivityOperatorsValid = !!formValues.productivity?.operatories;

  const isValid = 
    isTimerPerPatientValid &&
    isProductivityOperatorsValid &&
    formValues.isAssistedHygiene !== undefined;

  const hasWarning = formValues.productivity?.operatories === 'N/A'; 

  return {
    formValues,
    isValid,
    isTimerPerPatientValid,
    isProductivityOperatorsValid,
    hasWarning,
  };
};
