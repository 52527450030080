export const ZStack = ({ children, top, left, right, style, ...props }) => (
  <div
    {...props}
    style={{
      position: 'absolute',
      top,
      left,
      right,
      ...style,
    }}
  >
    {children}
  </div>
);
