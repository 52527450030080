import { Box, Row } from '../../../../../commonComponents';
import WarningAlertIcon from '../../../../../images/warning_alert_orange.svg';
import { Colors } from '../../../../../themes/colors';

export const ProfessionalInfoSection = ({
  title,
  value,
  isSameLine = false,
  showWarningIcon = false,
}) => (
  <Box
    direction={isSameLine ? 'row' : 'column'}
    gap={isSameLine ? 8 : 4}
    alignItems={isSameLine ? 'center' : 'flex-start'}
  >
    <Row gap={8} alignItems="center" alignSelf="flex-start">
      <b style={{ color: Colors.neutral_600, fontSize: 20 }}>{title}:</b>
      {showWarningIcon ? <img src={WarningAlertIcon} alt="Warning Alert Icon" width={18} /> : null}
    </Row>

    <span
      style={{
        fontSize: 16,
        color: Colors.neutral_600,
        ...(isSameLine && {
          flex: 1,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }),
      }}
    >
      {value}
    </span>
  </Box>
);
