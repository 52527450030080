import { Box, Divider, Row, Text } from '../../../../commonComponents';
import { BadgeList } from '../../../../commonComponents/BadgeList';
import { Column } from '../../../../commonComponents/Column';
import { AttendanceScoreLevels, BadgeTypes } from '../../../../constants';
import BadgeIcon from '../../../../images/attendance/BadgeIcon';
import { RisingStar } from '../../../../images/attendance/RisingStar';
import { Colors } from '../../../../themes/colors';

export const BadgesCard = ({ professional }) => {
  const attendanceScoreLevel = professional?.badges?.find(
    (badge) => badge.type === BadgeTypes.AttendanceScore,
  )?.level;

  let badgeInfo;
  if (attendanceScoreLevel === 0) {
    badgeInfo = AttendanceScoreLevels.risingStar;
  } else if (attendanceScoreLevel === 1) {
    badgeInfo = AttendanceScoreLevels.bronze;
  } else if (attendanceScoreLevel === 2) {
    badgeInfo = AttendanceScoreLevels.silver;
  } else if (attendanceScoreLevel === 3) {
    badgeInfo = AttendanceScoreLevels.gold;
  }

  return (
    <Column
      paddingTop={24}
      paddingBottom={32}
      paddingHorizontal={32}
      backgroundColor={Colors.white}
      style={{
        borderRadius: 8,
        boxShadow: '0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
        overflow: 'hidden',
      }}
    >
      <Column gap={8}>
        <Text bold fontSize={20} marginBottom={12}>
          Badges:
        </Text>
        <BadgeList professional={professional} />

        {badgeInfo && (
          <>
            <Divider height={1} />

            <Row gap={12}>
              <Box marginTop={-2}>
                {badgeInfo.rank === 'rising-star' ? (
                  <RisingStar />
                ) : (
                  <BadgeIcon rank={badgeInfo?.rank} />
                )}
              </Box>
              <Text>
                <Text fontSize={18} bold>
                  {badgeInfo.title}:
                </Text>{' '}
                {badgeInfo.description}
              </Text>
            </Row>
          </>
        )}
      </Column>
    </Column>
  );
};
