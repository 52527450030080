import { UserProfession } from '../../../../enums/UserProfession';
import BadgeIcon from '../../../../images/attendance/BadgeIcon';
import defaultProfile from '../../../../images/HY_tooth.png';
import HeartBorderedIcon from '../../../../images/icons/HeartBorderedIcon';
import ratingIcon from '../../../../images/star_icon.svg';
import { Colors } from '../../../../themes/colors';
import { getPrioritizedBadges, reorderBadges } from '../../../candidates/utils';
import { BestPickLabel } from './BestPickLabel';

export const CandidateCard = ({
  item,
  onClick,
  isSelected = false,
  isBestPick,
  containerStyle,
}) => {
  const isBadgesV2 = !!item?.candidate?.badges?.length;
  const showBadges = isBadgesV2 || item?.candidate?.currentRank?.rank;

  let displayedBadges = [];
  if (isBadgesV2) {
    displayedBadges = reorderBadges(
      getPrioritizedBadges({
        badges: item?.candidate?.badges?.sort((a, b) => a.type.localeCompare(b.type)),
      }),
      { inverted: true },
    );
  }

  return (
    <div
      style={{
        backgroundColor: isSelected ? Colors.white : Colors.neutral_50,
        boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.15)',
        width: 208,
        height: 184,
        cursor: 'pointer',
        ...containerStyle,
      }}
      onClick={onClick}
    >
      <div
        style={{
          position: 'relative',
          height: 4,
          backgroundColor: isSelected || isBestPick ? Colors.secondary_500 : 'transparent',
          width: 'calc(100% + 2px)',
          marginLeft: -1,
        }}
      >
        {isBestPick && <BestPickLabel />}
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 12,
          paddingTop: 12,
        }}
      >
        <div
          style={{
            position: 'relative',
            border: `3px solid ${item?.isFavorite ? Colors.favorites_400 : 'transparent'}`,
            width: 62,
            height: 62,
            borderRadius: 62,
            alignItems: 'center',
            justifyContent: 'center',
            padding: 2,
          }}
        >
          {item?.candidate && item?.candidate.profile_image ? (
            <img
              src={item?.candidate.profile_image}
              style={{
                width: 62,
                height: 62,
                borderRadius: 31,
                objectFit: 'cover',
              }}
              alt="profile_image"
            />
          ) : (
            <div className="default_circle_container">
              <img src={defaultProfile} alt="profile_image" width="62px" />
            </div>
          )}
          {item?.isFavorite && (
            <div style={{ position: 'absolute', top: '66%', right: -2 }}>
              <HeartBorderedIcon width={18} />
            </div>
          )}
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <img
            src={ratingIcon}
            alt="green"
            style={{
              width: 20,
              height: 20,
              margin: '0px 5px 3px 0px',
            }}
          />
          <b
            style={{
              fontSize: 16,
              marginRight: 5,
              color: Colors.neutral_600,
              fontFamily: 'Nunito',
            }}
          >
            {item?.candidate?.average_rating?.toFixed(1) || '5.0'}
          </b>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: 4,
        }}
      >
        <b
          style={{
            fontSize: 18,
            color: Colors.neutral_600,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 180,
          }}
        >
          {`${item?.candidate?.first_name} ${item?.candidate?.last_name}`}
        </b>
        <span
          style={{
            fontSize: 16,
            color: Colors.neutral_500,
            marginBottom: 4,
          }}
        >
          {UserProfession.getName(item?.candidate?.license?.profession)}
        </span>
      </div>

      {showBadges ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 4,
            marginTop: 2,
          }}
        >
          {isBadgesV2 ? (
            displayedBadges?.map((badge) => (
              <img
                key={badge.type}
                className="badge"
                src={badge.imageUrl}
                alt={badge.label}
                style={{ width: 26, height: 26 }}
              />
            ))
          ) : (
            <BadgeIcon rank={item?.candidate?.currentRank?.rank} />
          )}
        </div>
      ) : null}
    </div>
  );
};
