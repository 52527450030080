/* eslint-disable import/prefer-default-export */
import { GrowthBook } from '@growthbook/growthbook-react';
import { LocalStorageStickyBucketService } from '@growthbook/growthbook';

export const growthbook = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: process.env.REACT_APP_GROWTHBOOK_KEY,
  enableDevMode: process.env.REACT_APP_TEMP_MEE_ENV === 'development',
  subscribeToChanges: true,
  trackingCallback: (experiment, result) => {
    console.log('Experiment Viewed', {
      experimentId: experiment.key,
      variationId: result.key,
    });
  },
  stickyBucketService: new LocalStorageStickyBucketService(),
});

export const showProfessionalAttendanceBadges = () =>
  growthbook.isOn('show-professional-attendance-badges');
export const showShowWCManagementPage = () => growthbook.isOn('should-display-wc-managment-page');
export const showDecoupledDntEodFlow = () => growthbook.isOn('show-decoupled-dnt-eod-flow');
export const showCandidateOvertime = () => growthbook.isOn('should-use-overtime');
export const showClockInOutFeW2 = () => growthbook.isOn('show-clock-in-out_fe-w2');
export const showClockInOutFe1099 = () => growthbook.isOn('show-clock-in-out_fe-1099');
export const getShouldShowFrontDeskSpecialty = () =>
  growthbook.isOn('should-show-frontdesk-specialty');
export const getShouldOfficeShowEfda = () => growthbook.isOn('should-office-show-efda');
export const getShouldOfficeShowFrontDeskProfession = () =>
  growthbook.isOn('should-office-show-frontdesk-profession');
export const getShouldAllowOfficeToPostProcedure = () =>
  growthbook.isOn('should-allow-office-to-post-procedure');
export const getShouldUsingCredentialServiceEndpoint = () =>
  growthbook.isOn('should-use-credential-service-endpoint');
export const getShouldFilterRenouncedProcedures = () =>
  growthbook.isOn('should-filter-renounced-procedures');
export const getShouldUseMetricsService = () => growthbook.isOn('use-metrics-service');
export const shouldUseNewOfferExpiration = () => growthbook.isOn('use-new-offer-expiration');
export const shouldDisplayPopupNjEmployeeLaunch = () =>
  growthbook.isOn('display-popup-nj-employee-launch');
export const enableNotificationReminder = () => growthbook.isOn('enable-notification-reminder');
export const enableChatsUnread = () => growthbook.isOn('enable-chats-unread');
export const getShouldEnableUnlimitedCandidatesList = () =>
  growthbook.isOn('enable-unlimited-candidates-list');

export const getShouldEnableCandidatesListSort = () =>
  growthbook.isOn('enable-candidates-list-sort');

export const enableLastJobPostedReminders = () => growthbook.isOn('enable-last-job-posted-reminders');
export const enablePaymentExpirationReminders = () => growthbook.isOn('enable-payment-expiration-reminders');
export const enableProfileCompletenessReminders = () => growthbook.isOn('enable-profile-completeness-reminders');
export const getHideNotificationsOnParentOffice = () => growthbook.isOn('hide-notifications-on-parent-office');

export const getEnableTappableOfficeRatting = () => growthbook.isOn('enable-tappable-office-rating');

export const getShouldEnableAdjustmentReasonDropdown = () =>
  growthbook.isOn('should-enable-adjustment-reason-dropdown');