import { Colors } from '../../themes/colors';

export const TableData = ({ text, bold = false }) => (
  <td
    style={{
      textAlign: 'center',
      fontFamily: 'Nunito',
      fontSize: 16,
      color: Colors.neutral_600,
      fontWeight: bold ? 700 : 400,
      maxWidth: 200,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }}
  >
    {text}
  </td>
);
