export const Grid = ({ gridTemplateColumns, gridColumnGap, children, style }) => (
  <div
    style={{
      display: 'grid',
      gridTemplateColumns,
      gridColumnGap,
      ...style,
    }}
  >
    {children}
  </div>
);
