import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { fetchJob } from '../../actions';
import { Box, Column, Grid, ScreenTitle } from '../../commonComponents';
import { Colors } from '../../themes/colors';
import { BadgesCard } from './components/BadgesCard/BadgesCard';
import { ProfileCard } from './components/ProfileCard/ProfileCard';

export const ProfessionalProfile = () => {
  const params = useParams();
  const { jobId } = params || {};
  const history = useHistory();
  const dispatch = useDispatch();
  const candidateId = history?.location?.state?.candidateId;
  const { job, allCandidates } = useSelector((state) => state.job);
  const candidate = allCandidates?.find((counter) => counter?.candidate?.id === candidateId);
  const professional = candidate ? candidate?.candidate : job?.offer_final_reciever;

  useEffect(() => {
    dispatch(fetchJob({ jobId }));
  }, [jobId, dispatch]);

  return (
    <Box className="component_container">
      <Column className="floating_container">
        <ScreenTitle title="Professional Profile" onBackClick={() => history.goBack()} />

        <Column
          marginHorizontal={48}
          marginBottom={40}
          paddingBottom={40}
          backgroundColor={Colors.neutral_50}
          paddingHorizontal={32}
          paddingTop={32}
        >
          {professional ? (
            <Grid gridTemplateColumns="1fr 1fr" gridColumnGap={12}>
              <ProfileCard professional={professional} />
              <Column gap={12}>
                <BadgesCard professional={professional} />
              </Column>
            </Grid>
          ) : (
            <ClipLoader sizeUnit="px" size={50} color={Colors.primary_500} loading />
          )}
        </Column>
      </Column>
    </Box>
  );
};
