import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { AppRoute } from './commonComponents/AppRoute';
import About from './components/About';
import Alerts from './components/Alerts';
import Help from './components/Help';
import HowItWorks from './components/HowItWorks';
import JobDetails from './components/JobDetails/JobDetails';
import NPSReview from './components/NPSReview';
import Payment from './components/Payments/Payment';
import PPScreen from './components/Request/pp/PPScreen';
import SideBar from './components/SideBar';
import TopNavBar from './components/TopNavBar';
import UserAuth from './components/authentication/UserAuth';
import Profile from './components/profile/Profile';

import ForgotPassword from './components/authentication/ForgotPassword';
import { AdditionalOfficeInformation } from './components/registration/AdditionalOfficeInformation';
import appHistory from './history';

import { INITIAL_LOADING, hideCreateJobMessage, hideMessage, initialLoading } from './actions';
import { createLoadingSelector } from './api/selectors';
import ErrorShower from './commonComponents/ErrorShower';
import UnavalibleMobile from './commonComponents/UnavalibleMobile';
import Footer from './commonComponents/footer';
import { ContactInformation } from './components/registration/ContactInformation';
import { GeneralOfficeInformation } from './components/registration/GeneralOfficeInformation';
import { WorkerClassification as WorkerClassificationSignUp } from './components/registration/WorkerClassification';

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import CustomPortal from './commonComponents/CustomPortal';
import CustomSpinner from './commonComponents/CustomSpinner';
import CancellationFeedback from './components/CancellationFeedback';
import Dashboard from './components/Dashboard/Dashboard';
import InvoiceList from './components/Dashboard/ReceiptList/InvoiceList';
import ReceiptList from './components/Dashboard/ReceiptList/ReceiptList';
import ExpiredCounterOffers from './components/ExpiredCounterOffers';
import Inbox from './components/Inbox';
import ParkingInfoPopup from './components/ParkingInfoPopup';
import PermanentDashboard from './components/PermanentJobs/PermanentDashboard';
import PermanentsJobs from './components/PermanentJobs/PermanentsJobs';
import ReferralScreen from './components/Referral/ReferralScreen';
import AdjustHoursScreen from './components/Request/AdjustHoursScreen';
import VirtualJobRedirection from './components/VirtualJobRedirection';
import AdjustmentScreen from './components/adjustments/AdjustmentScreen';
import OpenJobScreen from './components/counterJobs/OpenJobScreen';
import Chat from './components/messages/Chat';

/* eslint import/no-cycle: [2, { ignoreExternal: true }] */
/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import CreateJobPortal from './commonComponents/CreateJobPortal';
import { AcceptTermsPopup } from './components/AcceptTermsPopup';
import { AccountInformation } from './components/AccountInformation/AccountInformation';
import { ConfirmCandidate } from './components/ConfirmCandidate/ConfirmCandidate';
import { ConfirmDualModelTermsPopup } from './components/ConfirmDualModelTermsPopup/ConfirmDualModelTermsPopup';
import TransactionsInfo from './components/Dashboard/ReceiptList/TransactionsInfo';
import EndOfDay from './components/EndOfDay';
import { ShiftList } from './components/JobList/ShiftList/ShiftList';
import { TodayJobs } from './components/JobList/TodayJobs';
import JobSummaryRating from './components/JobSummaryRating';
import LocumTenensDentist from './components/LocumTenensDentist';
import { NewBadgeAnnouncementPopup } from './components/NewBadgeAnnouncementPopup';
import { NewPhotoUploadFeaturePopup } from './components/NewPhotoUploadFeaturePopup';
import NotificationSettings from './components/NotificationSettings/NotificationSettings';
import { OfficeEfdaOnboardingPopup } from './components/OfficeEfdaOnboardingPopup';
import { OfficeFrontDeskOnboardingPopup } from './components/OfficeFrontDeskOnboardingPopup';
import { OfficeTimePerPatientPopup } from './components/OfficeTimePerPatientPopup/OfficeTimePerPatientPopup';
import { ProfessionalProfile } from './components/ProfessionalProfile/ProfessionalProfile';
import { ProfessionalsHub } from './components/ProfessionalsHub/ProfessionalsHub';
import { InviteProfessional } from './components/ProfessionalsHub/components/InviteProfessional/InviteProfessional';
import { OfficeJobInvites } from './components/ProfessionalsHub/components/OfficeJobInvites/OfficeJobInvites';
import {
  EditShiftContent,
  ReviewContent,
  ShiftDetailsContent,
} from './components/Request/multiday/screens';
import { EditOpenJob } from './components/Request/temp/EditOpenJob';
import { Schedule } from './components/Schedule/Schedule';
import { SendFeedback } from './components/SendFeedback';
import { SuccessRegistrationPopup } from './components/SuccessRegistrationPopup';
import { WorkerClassification } from './components/WorkerClassification';
import { SuccessPopup as SuccessWorkerClassificationPopup } from './components/WorkerClassification/components/SuccessPopup';
import { WorkerClassificationManagement } from './components/WorkerClassificationManagement';
import { growthbook, showDecoupledDntEodFlow, showShowWCManagementPage } from './growthbook';

export default () => {
  const dispatch = useDispatch();

  const loadingSelector = createLoadingSelector([INITIAL_LOADING]);

  const isLoading = useSelector((state) => loadingSelector(state));
  const user = useSelector((state) => state.user.selectedChildOffice);
  const missingWorkerClassification =
    user?.isWorkerClassificationEnabled && !user?.workerClassification?.classificationType;
  const message = useSelector((state) => state.message.message);
  const allowedRequest = user?.allowedRequest || {};

  const [requestedUser, setRequestedUser] = useState(false);

  const homePath = process.env.PUBLIC_URL;
  const isMobileTablet = window.innerWidth <= 760;

  useEffect(() => {
    dispatch(initialLoading());

    setRequestedUser(true);
  }, []);

  useEffect(() => {
    if (requestedUser && user) {
      growthbook.setAttributes({
        id: user?.id,
        userType: user?.user_type,
        ...(user?.address && user?.address[0] && { state: user.address[0].state }),
      });
    }
  }, [user, requestedUser]);

  const { text, title, route, params, isSuccess, isError, popupProps, jobData } = message;
  if (isMobileTablet) {
    return <UnavalibleMobile />;
  }
  if (isLoading || !requestedUser) {
    return <CustomSpinner isLoading={isLoading} />;
  }

  if (requestedUser && (!user || !user.is_profile_complete || user.user_type !== 'DNT')) {
    return (
      <Router basename={homePath} history={appHistory}>
        <div style={{ backgroundColor: '#FAFAFA' }}>
          <Switch>
            <AppRoute title="Login" path="/" exact component={UserAuth} />

            <AppRoute title="Forgot Password" path="/forgotpassword" component={ForgotPassword} />

            <AppRoute
              title="Registration - Contact Information"
              path="/registration"
              exact
              component={() => <ContactInformation />}
            />

            <AppRoute
              title="Registration - General Office Information"
              path="/registration/admin"
              component={(props) => <GeneralOfficeInformation {...props} />}
            />

            <AppRoute
              title="Registration - Additional Office Information"
              path="/registration/office-info"
              component={() => <AdditionalOfficeInformation isRegistration />}
            />

            <AppRoute
              title="Registration - Worker Classification"
              path="/registration/worker-classification"
              component={() => <WorkerClassificationSignUp />}
            />

            <Route path="*" element={<Redirect to="/" />} />
          </Switch>
        </div>
        <ErrorShower />
        {text || title ? (
          <CustomPortal
            isSuccess={isSuccess}
            isError={isError}
            popupProps={popupProps}
            title={title}
            text={text}
            route={route}
            params={params}
            submitPressed={() => dispatch(hideMessage())}
          />
        ) : null}
        <SuccessWorkerClassificationPopup />
      </Router>
    );
  }

  return (
    <Router basename={homePath} history={appHistory} style={{ backgroundColor: '#FAFAFA' }}>
      <Route path="/" component={TopNavBar} />
      <div style={{ display: 'flex', flex: 1, minHeight: 'calc(100% - 180px)' }}>
        <Route path="/" component={(props) => <SideBar {...props} />} />
        <Switch>
          <Route exact path="/dashboard/home" render={() => <Redirect to="/" />} />

          {/* <Route path="*" element={<Dashboard />} /> */}
          <AppRoute title="Dashboard" path="/" exact component={() => <Dashboard />} />

          <AppRoute
            title="Account Information"
            path="/account/information"
            component={(props) => <AccountInformation {...props} />}
          />

          <AppRoute title="Invoices" path="/account/invoices" component={() => <InvoiceList />} />

          <AppRoute
            title="Edit Open Job"
            path="/dashboard/job/:jobId/edit"
            component={(props) => <EditOpenJob {...props} />}
          />

          <AppRoute
            title="Permanent Job Details"
            path="/dashboard/permanentJobs/:jobId"
            component={(props) => <PermanentDashboard {...props} />}
          />

          <AppRoute
            title="Permanent Jobs"
            path="/dashboard/permanentJobs"
            component={(props) => <PermanentsJobs {...props} />}
          />

          <AppRoute
            title="Inbox"
            path="/dashboard/inbox"
            component={(props) => (
              <div className="component_container inbox_screen">
                <div className="floating_container">
                  <Inbox {...props} />
                </div>
              </div>
            )}
          />

          <AppRoute
            title="Chat"
            path="/dashboard/chat/:jobId"
            component={(props) => <Chat {...props} />}
          />

          <AppRoute
            title="Expired Counter Offers"
            path="/dashboard/jobs/:jobId/expired"
            exact
            component={(props) => <ExpiredCounterOffers {...props} />}
          />

          <AppRoute
            title="Adjust Hours"
            path="/dashboard/jobs/:jobId/adjust"
            component={(props) => <AdjustHoursScreen {...props} />}
          />

          {allowedRequest?.tempOrMdbShifts && !missingWorkerClassification && (
            <AppRoute
              title="Multi Day Shift Request"
              path="/dashboard/multi-day/info"
              component={(props) => <ShiftDetailsContent {...props} />}
            />
          )}

          <AppRoute
            title="Multi Day Shift Edit"
            path="/dashboard/multi-day/edit"
            component={(props) => <EditShiftContent {...props} />}
          />

          <AppRoute
            title="Multi Day Shift Confirm"
            path="/dashboard/multi-day/confirm"
            component={() => <ReviewContent />}
          />

          <AppRoute
            title="Permanent Hire Request"
            path="/dashboard/pprs"
            component={(props) => <PPScreen {...props} />}
          />

          <AppRoute
            title="Transaction History Details"
            path="/dashboard/receipts/transactions/:jobId"
            component={(props) => <TransactionsInfo {...props} />}
          />

          <AppRoute
            title="Payment History"
            path="/dashboard/receipts"
            component={(props) => <ReceiptList {...props} />}
          />

          <AppRoute
            title={"Today's Jobs"}
            path="/dashboard/jobs/today"
            component={(props) => <TodayJobs {...props} />}
          />

          <AppRoute
            title="Shifts Overview"
            path="/dashboard/jobs/:type"
            component={(props) => <ShiftList {...props} />}
          />

          <Route
            exact
            path="/dashboard/job/:jobId/redirect"
            component={(props) => <VirtualJobRedirection {...props} />}
          />

          <AppRoute
            title="Professional Profile"
            exact
            path="/dashboard/candidate/:jobId"
            component={(props) => <ProfessionalProfile {...props} />}
          />

          <AppRoute
            title="Confirm Candidate"
            exact
            path="/dashboard/job/:jobId/confirm/:candidateId"
            component={(props) => <ConfirmCandidate {...props} />}
          />

          <AppRoute
            title="Job Summary"
            path="/dashboard/job/:jobId/feedback"
            exact
            component={(props) => <EndOfDay {...props} />}
          />

          {showDecoupledDntEodFlow() && (
            <AppRoute
              title="Job Summary Rating"
              path="/dashboard/job/:jobId/feedback-rating"
              exact
              component={(props) => <JobSummaryRating {...props} />}
            />
          )}

          <AppRoute
            title="Job Details"
            path="/dashboard/job/:jobId/details"
            exact
            component={(props) => <JobDetails {...props} />}
          />

          <AppRoute
            title="Open Job Details"
            path="/dashboard/job/:jobId"
            component={(props) => <OpenJobScreen {...props} />}
          />

          <AppRoute
            title="NPS Review"
            path="/dashboard/nps"
            component={(props) => <NPSReview {...props} />}
          />

          <AppRoute
            title="Alerts"
            path="/dashboard/alerts"
            component={(props) => <Alerts {...props} />}
          />

          <AppRoute
            title="Schedule"
            path="/dashboard/calendar"
            component={(props) => <Schedule {...props} />}
          />

          <AppRoute
            title="Payment Information"
            path="/account/payment"
            exact
            component={(props) => <Payment {...props} />}
          />

          <AppRoute
            title="Referral"
            path="/dashboard/referral"
            component={(props) => <ReferralScreen {...props} />}
          />

          <AppRoute
            title="Help"
            path="/dashboard/help"
            component={(props) => <Help {...props} />}
          />

          <AppRoute
            title="About"
            path="/dashboard/about"
            component={(props) => <About {...props} />}
          />

          <AppRoute
            title="Notification Settings"
            path="/dashboard/notifications"
            component={(props) => <NotificationSettings {...props} />}
          />

          <AppRoute
            title="Cancellation Feedback"
            path="/dashboard/feedback/:jobId"
            component={(props) => <CancellationFeedback {...props} />}
          />

          <AppRoute
            title="Send Feedback"
            path="/account/feedback"
            component={(props) => <SendFeedback {...props} />}
          />

          <AppRoute
            title="Job Adjustment"
            path="/dashboard/adjustments/:jobId"
            component={(props) => <AdjustmentScreen {...props} />}
          />

          <AppRoute
            title="How It Works"
            path="/dashboard/howitworks"
            component={(props) => <HowItWorks {...props} />}
          />

          <AppRoute
            title="Locum Tenens Dentist"
            path="/dashboard/locum-tenens-dentist"
            component={(props) => <LocumTenensDentist {...props} />}
          />

          <AppRoute
            title="Worker Classification"
            path="/dashboard/worker-classification"
            component={(props) => <WorkerClassification {...props} />}
          />

          {showShowWCManagementPage() && (
            <AppRoute
              title="Worker Classification Management"
              path="/account/worker-classification-management"
              component={(props) => <WorkerClassificationManagement {...props} />}
            />
          )}

          <AppRoute
            title="Professionals Hub"
            path="/professionals-hub/:type"
            component={(props) => <ProfessionalsHub {...props} />}
          />

          <AppRoute
            title="Invite Professional"
            path="/invite-professional"
            component={(props) => <InviteProfessional {...props} />}
          />

          <AppRoute
            title="Office Job Invites"
            path="/invites"
            component={(props) => <OfficeJobInvites {...props} />}
          />

          <AppRoute title="Account" path="/account" component={(props) => <Profile {...props} />} />

          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </div>
      <div className="footer-box">
        <Footer />
      </div>
      {text || title ? (
        <CustomPortal
          isSuccess={isSuccess}
          isError={isError}
          popupProps={popupProps}
          title={title}
          text={text}
          route={route}
          params={params}
          submitPressed={() => dispatch(hideMessage())}
        />
      ) : null}
      {jobData && (
        <CreateJobPortal
          jobData={jobData}
          route={route}
          params={params}
          submitPressed={() => dispatch(hideCreateJobMessage())}
        />
      )}
      <ConfirmDualModelTermsPopup />
      <ParkingInfoPopup />
      <SuccessRegistrationPopup />
      <AcceptTermsPopup />
      <SuccessWorkerClassificationPopup />
      <OfficeTimePerPatientPopup />
      <NewPhotoUploadFeaturePopup />
      <OfficeEfdaOnboardingPopup />
      <OfficeFrontDeskOnboardingPopup />
      <NewBadgeAnnouncementPopup />
      <ErrorShower />
    </Router>
  );
};
