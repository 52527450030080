export const candidateSortOptions = {
  previouslyRated: 'previously-rated',
  skillsMatch: 'skills-match',
  localFavorites: 'local-favorites',
};

export const defaultFilters = {
  sort: candidateSortOptions.localFavorites,
  page: 1,
  totalPages: 1,
  limit: 5,
};
