import moment from 'moment';
import { useSelector } from 'react-redux';
import { Box, Column, Divider, Text } from '../../../../commonComponents';
import { ProfessionalHeader } from '../../../../commonComponents/ProfessionalHeader';
import { useUserSelector } from '../../../../hooks/useUserSelector';
import { Colors } from '../../../../themes/colors';
import { EducationUtils } from '../../../../utils/EducationUtils';
import { useCandidate } from '../../../CandidateList/hooks/useCandidate';
import { DnDaProcedures } from '../../../ConfirmCandidate/components/Procedures/DnDaProcedures';
import { RdhFdProcedures } from '../../../ConfirmCandidate/components/Procedures/RdhFdProcedures';
import { ProfessionalInfoSection } from './components/ProfileInfoSection';

export const ProfileCard = ({ professional }) => {
  const user = useUserSelector();
  const job = useSelector((state) => state.job.job);
  const {
    specialtyMatch,
    insuranceMismatch,
    userSpecialties,
    softwares,
    licenseNumber,
    issueDate,
    nationalProviderIdentifier,
    shouldShowNpi,
    professionName,
    candidateInsurances,
  } = useCandidate(user, professional, job);

  return (
    <Box
      paddingTop={24}
      paddingBottom={32}
      paddingHorizontal={32}
      backgroundColor={Colors.white}
      style={{
        borderRadius: 8,
        boxShadow: '0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
        overflow: 'hidden',
      }}
    >
      <ProfessionalHeader professional={professional} />

      <Divider height={1} />

      <Column gap={20}>
        <ProfessionalInfoSection
          title="Education"
          value={
            <Text>
              {professional?.license?.school}
              <br />
              Graduation Date: {moment(professional?.license?.graduationDate).format('MM/DD/YYYY')}
            </Text>
          }
        />
        <ProfessionalInfoSection title="Profession" value={professionName} />
        <Column gap={4}>
          <ProfessionalInfoSection title="License #" isSameLine value={licenseNumber} />
          <ProfessionalInfoSection
            title="Issued by State of"
            isSameLine
            value={professional?.license?.state}
          />
          <ProfessionalInfoSection
            title="Year Issued"
            isSameLine
            value={moment(issueDate).format('YYYY')}
          />
        </Column>

        {nationalProviderIdentifier && shouldShowNpi && (
          <ProfessionalInfoSection title="NPI" value={nationalProviderIdentifier} />
        )}

        <ProfessionalInfoSection
          title="Years of Experience"
          value={professional?.years_of_experience}
        />

        <ProfessionalInfoSection
          title="Software"
          value={softwares?.join(', ')}
          showWarningIcon={!specialtyMatch || insuranceMismatch}
        />

        {shouldShowNpi && (
          <ProfessionalInfoSection
            title="Insurance"
            value={candidateInsurances?.join(', ')}
            showWarningIcon={insuranceMismatch}
          />
        )}

        <ProfessionalInfoSection
          title="Specialty(ies)"
          value={EducationUtils.mapToRemoveSpecialtyPrefix(userSpecialties).join(', ')}
          showWarningIcon={!specialtyMatch || insuranceMismatch}
        />

        <Box style={{ width: '100%' }}>
          <Text bold fontSize={20}>
            Experienced Procedures:
          </Text>

          <RdhFdProcedures candidate={professional} job={job} />

          <DnDaProcedures candidate={professional} job={job} />
        </Box>
      </Column>
    </Box>
  );
};
