import defaultProfile from '../images/HY_tooth.png';
import HeartBorderedIcon from '../images/icons/HeartBorderedIcon';
import ratingIcon from '../images/star_icon.svg';
import { Colors } from '../themes/colors';
import { Center } from './Center';
import { Column } from './Column';
import { Row } from './Row';
import { Text } from './Text';
import { ZStack } from './ZStack';

export const ProfessionalHeader = ({ professional }) => {
  const isFavorite = professional?.isFavorite;

  return (
    <Row
      style={{
        flex: 1,
      }}
    >
      <Center
        style={{
          position: 'relative',
          width: 104,
          height: 104,
          borderRadius: 104,
          alignItems: 'center',
          justifyContent: 'center',
          border: `3px solid ${isFavorite ? Colors.favorites_400 : 'transparent'}`,
          padding: 2,
        }}
      >
        {professional?.profile_image ? (
          <img
            src={professional.profile_image}
            style={{
              width: 104,
              height: 104,
              borderRadius: 104,
              objectFit: 'contain',
            }}
            alt="profile_image"
          />
        ) : (
          <img
            src={defaultProfile}
            alt="profile_image"
            style={{
              width: 55,
              height: 55,
              objectFit: 'contain',
            }}
          />
        )}
        {isFavorite && (
          <ZStack top="72%" right={-4}>
            <HeartBorderedIcon />
          </ZStack>
        )}
      </Center>

      <Center style={{ marginLeft: 20, flex: 1, alignItems: 'flex-start', overflow: 'hidden' }}>
        <Column
          flex={1}
          style={{
            flex: 1,
            overflow: 'hidden',
            width: '100%',
          }}
        >
          <Row
            style={{
              width: '100%',
              alignItems: 'center',
              overflow: 'hidden',
            }}
          >
            <Text
              bold
              fontSize={20}
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {`${professional?.first_name} ${professional?.last_name}`}
            </Text>

            <Row alignItems="center" style={{ marginLeft: 10 }}>
              <img
                src={ratingIcon}
                alt="rating"
                style={{
                  width: 20,
                  height: 20,
                  margin: '0px 5px 3px 0px',
                }}
              />
              <Text bold marginRight={5}>
                {professional?.average_rating?.toFixed(1) || '5.0'}
              </Text>
            </Row>
          </Row>

          <Text>
            {professional?.license?.subProfession ?? professional?.license?.profession} Credentials
            Verified
          </Text>
        </Column>
      </Center>
    </Row>
  );
};
