import AttendanceBadge from '../components/candidates/AttendanceBadge';
import MultiProfessionalBadges from '../components/candidates/MultiProfessionalBadges';
import { Box } from './Box';

export const BadgeList = ({ professional }) => {
  const isBadgesV2 = !!professional?.badges?.length;

  if (!isBadgesV2 && !professional?.currentRank?.rank) {
    return null;
  }

  return (
    <Box data-testid="badge-list">
      {isBadgesV2 ? (
        <MultiProfessionalBadges badges={professional?.badges} />
      ) : (
        <AttendanceBadge currentRank={professional?.currentRank} />
      )}
    </Box>
  );
};
