import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, Column, Label, MultipleOptions, Row } from '../../../../commonComponents';
import { CollapsibleCard } from '../../../../commonComponents/CollapsibleCard';
import { SoftwareUtils } from '../../../../utils/SoftwareUtils';
import { useFormContext } from '../../hooks/useFormContext';
import { CardTitle } from '../CardTitle';
import { useSoftware } from './hooks/useSoftware/useSoftware';

export const SoftwareCard = ({ isOpen, onToggle }) => {
  const {
    setValue,
    control,
  } = useFormContext();
  const { formValues, isValid } = useSoftware(control);
  const officeEducation = useSelector((state) => state.user.officeEducation);

  const softwares = useMemo(() => 
    SoftwareUtils.getSoftwareTranslations(officeEducation?.softwares), [officeEducation]
  );

  const handleOnClick = (code) => {
    let updatedSoftwares;

    if (formValues.softwares.includes(code)) {
      updatedSoftwares = formValues.softwares.filter((item) => item !== code);
    } else {
      updatedSoftwares = [...formValues.softwares, code];
    }
  
    updatedSoftwares.sort();
  
    setValue('softwares', updatedSoftwares);
  };

  return (
    <CollapsibleCard
      TitleComponent={() => <CardTitle name="Software" showWarningIcon={!isOpen && !isValid} />}
      isOpen={isOpen}
      onToggle={onToggle}
    >
      <Column gap={20} paddingHorizontal={80} marginBottom={40}>
        <Box>
          <Row alignItems="baseline">
            <Label
              text="What software does your office use?"
              marginBottom={20}
              error={!isValid}
            />
          </Row>
          <MultipleOptions
            selected={formValues.softwares}
            onClick={handleOnClick}
            options={softwares}
          />
        </Box>
      </Column>
    </CollapsibleCard>
  );
};
