import moment from 'moment';
import 'moment-timezone';
import isHoliday from '../../../../isHoliday';

export const checkIfAnyHoliday = (dates) => Object.values(dates).some((date) => isHoliday(date));

export const createObjOfDates = (datesArr) => {
  let objHelper = {};
  datesArr.forEach((date) => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    objHelper = { ...objHelper, [formattedDate]: date };
  });
  return objHelper;
};

export const handleConfirmRemoveDate = (jobDates, deletedDate) => {
  const jobData = {};
  const datesFilteredArr = jobDates.filter((date) => date !== deletedDate);
  datesFilteredArr.forEach((date) => {
    jobData[date] = {};
  });
  return jobData;
};

/**
 * Converts an array of date strings to local time strings in a specified timezone.
 * @param {string[]} dateStrings - Array of date strings in 'YYYY-MM-DD' format.
 * @param {string} timeZone - Timezone identifier (e.g., 'America/New_York').
 * @returns {string[]} Array of local time strings formatted according to the timezone.
 */
export const convertDatesToTimezone = (dateStrings, timeZone) =>
  dateStrings.map((dateString) => {
    const date = moment.tz(
      `${dateString} ${moment().format('HH:mm:ss')}`,
      'YYYY-MM-DD HH:mm:ss',
      timeZone,
    );

    return date.format('YYYY-MM-DD h:mm A');
  });
