export const Box = ({
  children,
  className,
  width,
  maxWidth,
  height,
  gap,
  gridTemplateColumns,
  top,
  left,
  right,
  bottom,
  padding,
  paddingLeft,
  paddingRight,
  paddingTop,
  paddingBottom,
  paddingHorizontal,
  paddingVertical,
  backgroundColor,
  marginBottom,
  marginTop,
  marginLeft,
  marginRight,
  marginHorizontal,
  marginVertical,
  position,
  alignItems,
  alignSelf,
  style,
  display = 'flex',
  direction = 'column',
  ...props
}) => (
  <div
    {...props}
    className={className}
    style={{
      width,
      maxWidth,
      height,
      flexDirection: direction,
      gap,
      backgroundColor,
      display,
      gridTemplateColumns,
      position,
      alignItems,
      alignSelf,
      padding,
      marginRight: marginRight || marginHorizontal,
      marginLeft: marginLeft || marginHorizontal,
      marginBottom: marginBottom || marginVertical,
      marginTop: marginTop || marginVertical,
      paddingRight: paddingRight || paddingHorizontal,
      paddingLeft: paddingLeft || paddingHorizontal,
      paddingTop: paddingTop || paddingVertical,
      paddingBottom: paddingBottom || paddingVertical,
      top,
      left,
      right,
      bottom,
      ...style,
    }}
  >
    {children}
  </div>
);
