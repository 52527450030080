import { UserProfession, UserSubProfession } from '../../../enums/UserProfession';
import { filterObjectKeys } from '../../../utils/Object';
import { removePrefixes } from '../utils';

export const useCandidate = (user, candidate, job) => {
  const officeInsurances = user?.office_info?.insurances;
  const candidateEducation = candidate?.professional_info?.education;
  const educationInfo = candidateEducation?.[job?.profession];
  const candidateInsurances = candidate?.professional_info?.insurances;
  const userSpecialties = Object.keys(
    filterObjectKeys(educationInfo?.specialtiesAndProcedures, 'specialty'),
  );

  const userProcedures = Object.keys(
    filterObjectKeys(educationInfo?.specialtiesAndProcedures, 'procedure'),
  );

  const specialtiesAndProcedures = educationInfo?.specialtiesAndProcedures ?? {};
  const professionProcedures = removePrefixes(educationInfo?.specialtiesAndProcedures ?? {});

  const isSpecialty =
    job?.skills?.[0]?.code?.includes('specialty') ||
    job?.specialty?.toLowerCase()?.includes('specialty');
  const specialtyMatch = userSpecialties.includes(job?.specialty) && isSpecialty;

  const insuranceMismatch =
    officeInsurances?.length &&
    candidateInsurances?.length &&
    !officeInsurances?.some((insurance) => candidateInsurances?.includes(insurance));

  const professionName = (() => {
    const subProfession = candidate?.license?.subProfession;
  
    if (subProfession === UserSubProfession.EFDA) {
      return 'Expanded Functions DA';
    }
  
    return candidate?.license?.professionName;
  })();
    
  const softwares = candidateEducation?.[job?.profession]?.softwares;

  const licenseNumber = candidate?.license?.credentials?.find(
    ({ entry_field }) => entry_field?.license,
  )?.entry_field?.license;
  const issueDate = candidate?.license?.credentials?.find(
    ({ entry_field }) => entry_field?.issue_date,
  )?.entry_field?.issue_date;

  const nationalProviderIdentifier = candidate?.professional_info?.nationalProviderIdentifier;
  const shouldShowNpi = candidate?.license?.profession === UserProfession.DN;
    
  return {
    userSpecialties,
    userProcedures,
    candidateInsurances,
    // professionProcedures is only processed specialtiesAndProcedures, use only for showing
    professionProcedures,
    specialtiesAndProcedures,
    specialtyMatch,
    insuranceMismatch,
    softwares,
    licenseNumber,
    issueDate,
    nationalProviderIdentifier,
    shouldShowNpi,
    professionName,
  };
};
