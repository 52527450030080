import CloseIcon from '../../../../../../images/icons/CloseIcon';
import PlusIcon from '../../../../../../images/icons/PlusIcon';
import { Colors } from '../../../../../../themes/colors';
import { Value as DefaultValue } from '../../CollapsibleRow/Value';

export const Value = ({ values, showSelectedDates, handleRemoveDate, onToggle }) =>
  showSelectedDates ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        gap: 15,
      }}
    >
      {values?.map((date, index) => (
        <div key={date} style={{ display: 'flex', alignItems: 'center' }}>
          <span
            style={{
              fontFamily: 'Nunito',
              color: Colors.neutral_600,
              textTransform: 'capitalize',
            }}
          >
            {date}
          </span>
          <div
            onClick={(event) => {
              event.stopPropagation();
              handleRemoveDate(index);
            }}
            style={{
              marginLeft: 30,
              marginRight: -30,
              cursor: 'pointer',
            }}
            data-cy="remove-date"
            data-testid="remove-date"
          >
            <CloseIcon color={Colors.secondary_500} />
          </div>
        </div>
      ))}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          cursor: 'pointer',
          gap: 8,
        }}
        onClick={(event) => {
          event.stopPropagation();
          onToggle();
        }}
      >
        <PlusIcon color={Colors.secondary_500} />
        <span
          style={{
            fontFamily: 'Nunito',
            color: Colors.secondary_500,
            fontSize: 18,
            fontWeight: 700,
          }}
        >
          Add Dates
        </span>
      </div>
    </div>
  ) : (
    <DefaultValue
      customStyles={{
        color: Colors.neutral_300,
      }}
      text="Dates"
    />
  );
