import { AnimatePresence, motion } from 'framer-motion';
import moment from 'moment';
import BackButton from '../../../../../commonComponents/BackButton';
import CardSpinner from '../../../../../commonComponents/CardSpinner';
import history from '../../../../../history';
import BlockCrossIcon from '../../../../../images/icons/BlockCrossIcon';
import { Colors } from '../../../../../themes/colors';
import ConfirmPopup from '../../../../shared/ConfirmPopup';
import { ConfirmRequest } from '../../components/ConfirmRequest';
import JobMDBCard from '../../components/JobMDBCard';
import ProgressBar from '../../components/ProgressBar';
import { useReviewStep } from '../../hooks/useReviewStep';

export const ReviewContent = () => {
  const {
    jobDates,
    isLoading,
    jobInfo,
    showDeleted,
    deletedJob,
    editPressed,
    confirmRemoveDate,
    deletePressed,
    setShowDeleted,
  } = useReviewStep();

  if (!jobInfo?.length) {
    history.push('/dashboard/multi-day/info');
    return null;
  }

  if (isLoading) {
    return <CardSpinner />;
  }

  return (
    <div className="component_container">
      <div
        className="floating_container"
        style={{ flexDirection: 'column', justifyContent: 'flex-start' }}
      >
        <div style={{ marginTop: 20, marginLeft: 30 }}>
          <BackButton />
        </div>
        <ProgressBar step={2} />
        <motion.div
          layout
          style={{
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'grid',
            gridTemplateColumns: jobInfo?.length > 1 ? '438px 438px' : '438px',
            gridGap: 24,
            alignSelf: 'center',
            marginTop: 12,
          }}
        >
          <AnimatePresence>
            {jobInfo?.map((job) => (
              <JobMDBCard
                key={job.localDate}
                job={job}
                editHasBeenClicked={(data) => editPressed(data)}
                deleteJobPressed={(id) => deletePressed(id)}
              />
            ))}
          </AnimatePresence>
        </motion.div>

        <ConfirmRequest />
      </div>

      {showDeleted && (
        <ConfirmPopup
          Icon={() => (
            <div style={{ marginBottom: 20 }}>
              <BlockCrossIcon />
            </div>
          )}
          title="Delete Shift"
          description={
            <>
              Are you sure you would like to delete the shift for
              {'\n'}
              <strong>{moment(deletedJob).format('ddd, MMM DD, YYYY')}</strong>?
            </>
          }
          descriptionStyle={{ width: '76%', marginBottom: 50 }}
          leftButtonText="Go Back"
          leftButtonStyle={{
            backgroundColor: Colors.secondary_500,
          }}
          leftButtonAction={() => setShowDeleted(false)}
          rightButtonText="Delete"
          rightButtonAction={() => {
            confirmRemoveDate(jobDates, deletedJob);
            setShowDeleted(false);
          }}
        />
      )}
    </div>
  );
};
