import { motion } from 'framer-motion';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  EDIT_ACCOUNT_INFO_ACTION,
  EDIT_USER,
  editUser,
  editUserAccount,
  editUserOfficeInfo,
  fetchPartnershipOrganizations,
  getAllSoftwares,
  getOfficeEducation,
  getStateWorkerClassification,
  hidePartnershipCodeSuccessPopup,
  showRegistrationPopup,
  verifyPartnershipCode,
} from '../../../actions';
import { trackEvent } from '../../../api/analytics';
import { createLoadingSelector } from '../../../api/selectors';
import BackButton from '../../../commonComponents/BackButton';
import Button from '../../../commonComponents/Button';
import InputSelect from '../../../commonComponents/InputSelect';
import history from '../../../history';
import confirmCircle from '../../../images/confirm-circle.svg';
import greenCircleCheck from '../../../images/green-circle-check.svg';
import { Colors } from '../../../themes/colors';
import '../../../themes/office_info.scss';
import { filterObjectKeys } from '../../../utils/Object';
import { SoftwareUtils } from '../../../utils/SoftwareUtils';
import Tooltip from '../../Tooltip';
import ConfirmPopup from '../../shared/ConfirmPopup';
import { ContainerLayout, ContentLayout, Title } from '../components';
import { Label } from '../components/Label';
import { RadioOption } from '../components/RadioOption';
import { MultipleOptions } from './components/MultipleOptions';

const partnershipCodeVariants = {
  show: {
    opacity: 1,
    maxHeight: 200,
  },
  hide: {
    opacity: 0,
    maxHeight: 0,
  },
};

const timePerPatientOptions = ['N/A', '30 min', '45 min', '1 hr', '1.5 hr', '2+ hr'];

const actions = [EDIT_ACCOUNT_INFO_ACTION, EDIT_USER];
const loadingSelector = createLoadingSelector(actions);

export const AdditionalOfficeInformation = ({ isRegistration }) => {
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => loadingSelector(state));
  const selectedChildOffice = useSelector((state) => state.user.selectedChildOffice);
  const officeCredentials = useSelector((state) => state.userCredentials.credentials);

  const educationInfo = officeCredentials?.education;

  const [softwares, setSoftwares] = useState([]);
  const [insurances, setInsurances] = useState([]);
  const [isPrivatePracticeOrDSO, setIsPrivatePracticeOrDSO] = useState('');
  const [selectedSpecialty, setSelectedSpecialty] = useState(
    Object.keys(educationInfo?.specialtiesAndProcedures || {})[0] || '',
  );
  const [timePerPatientRDH, setTimePerPatientRDH] = useState('');
  const [timePerPatientDA, setTimePerPatientDA] = useState('');
  const [timePerPatientDN, setTimePerPatientDN] = useState('');
  const [partnershipOrganization, setPartnershipOrganization] = useState('');
  const [partnershipCode, setPartnershipCode] = useState('');
  const [isAssistedHygiene, setIsAssistedHygiene] = useState(undefined);
  const user = useSelector((state) => state.user.user);
  const [notes, setOfficeNotes] = useState(user?.notes_for_temp || '');

  const partnershipOrganizations = useSelector((state) => state.user.partnershipOrganizations);
  const showPartnershipCodeSuccess = useSelector((state) => state.user.showPartnershipCodeSuccess);
  const officeEducation = useSelector((state) => state.user.officeEducation);

  const translatedSoftwares = useMemo(() => 
    SoftwareUtils.getSoftwareTranslations(officeEducation?.softwares || []),
  [officeEducation?.softwares]);

  const isSubmitDisabled =
    softwares.length === 0 ||
    insurances.length === 0 ||
    isPrivatePracticeOrDSO === '' ||
    selectedSpecialty === '' ||
    timePerPatientRDH === '' ||
    timePerPatientDA === '' ||
    timePerPatientDN === '' ||
    isAssistedHygiene === undefined ||
    (partnershipOrganization && !selectedChildOffice?.partnerReferral);

  const handleSubmitButton = () => {
    const jobId = history.location?.state?.jobId;

    const data = {
      timePerPatientRDH,
      timePerPatientDA,
      timePerPatientDN,
      isAssistedHygiene,
      officeType: isPrivatePracticeOrDSO,
      is_info_asked: true,
      notes_for_temp: notes,
    };

    if (notes) {
      dispatch(editUserAccount({ notes_for_temp: notes }));
    }

    if (isRegistration) {
      const originalSoftwareValues = SoftwareUtils.getSoftwareOriginalValues(softwares);

      if (user?.isWorkerClassificationEnabled) {
        trackEvent('Office Sign up - Additional Information submitted', { ...data, softwares, insurances, partnershipOrganization });

        dispatch(
          editUser({
            data: { office_info: data },
            education: {
              specialtiesAndProcedures: {
                [selectedSpecialty]: [],
              },
              softwares: originalSoftwareValues,
            },
            insurances,
            location: '/registration/worker-classification',
          }),
        );
      } else {
        dispatch(
          editUser({
            data: { is_profile_complete: true, office_info: data },
            education: {
              specialtiesAndProcedures: {
                [selectedSpecialty]: [],
              },
              softwares: originalSoftwareValues,
            },
            insurances,
          }),
        );

        dispatch(showRegistrationPopup());
      }
      return;
    }

    dispatch(editUserOfficeInfo({ data, jobId }));
  };

  useEffect(() => {
    dispatch(getAllSoftwares());
    dispatch(fetchPartnershipOrganizations());
    dispatch(getStateWorkerClassification());
    dispatch(getOfficeEducation());
  }, [dispatch]);

  useEffect(() => {
    if (selectedChildOffice?.office_info) {
      setSoftwares(officeCredentials?.education?.softwares || []);
      setInsurances(officeCredentials?.insurances || []);
      setIsPrivatePracticeOrDSO(selectedChildOffice.office_info.officeType || '');
      setTimePerPatientRDH(selectedChildOffice.office_info.timePerPatientRDH);
      setTimePerPatientDA(selectedChildOffice.office_info.timePerPatientDA);
      setTimePerPatientDN(selectedChildOffice.office_info.timePerPatientDN);
      setIsAssistedHygiene(selectedChildOffice.office_info.isAssistedHygiene);
      setOfficeNotes(selectedChildOffice.notes_for_temp);
    }

    const partnerReferral = selectedChildOffice?.partnerReferral?.partnershipCode;

    if (partnerReferral) {
      setPartnershipCode(partnerReferral?.code);
      setPartnershipOrganization(partnerReferral?.organization);
    }
  }, [officeCredentials?.education?.softwares, officeCredentials?.insurances, selectedChildOffice]);

  const handleInsuranceClick = (code) => {
    if (insurances.includes(code)) {
      setInsurances(insurances.filter((provider) => provider !== code));
    } else {
      setInsurances([...insurances, code]);
    }
  };
  const handleSoftwareClick = (code) => {
    if (softwares.includes(code)) {
      setSoftwares(softwares.filter((software) => software !== code));
    } else {
      setSoftwares([...softwares, code]);
    }
  };

  return (
    <>
      <ContainerLayout>
        <div style={{ position: 'absolute', left: 60, top: 140 }}>
          <BackButton color={Colors.white} url="/registration/admin" />
        </div>
        <Title
          text="Additional Office Information"
          progress={user?.isWorkerClassificationEnabled ? 'step3' : 'step4'}
        />
        <ContentLayout>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 36 }}>
            <div>
              <Label text="Are you a private practice or DSO?" marginBottom={20} />
              <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
                <RadioOption
                  onClick={() => setIsPrivatePracticeOrDSO('private_practice_independent')}
                  text="Private Practice (Independent Office)"
                  checked={isPrivatePracticeOrDSO === 'private_practice_independent'}
                />
                <RadioOption
                  onClick={() => setIsPrivatePracticeOrDSO('private_practice_multi')}
                  text="Private Practice (Part of a Multi-Office Group)"
                  checked={isPrivatePracticeOrDSO === 'private_practice_multi'}
                />
                <RadioOption
                  onClick={() => setIsPrivatePracticeOrDSO('dso')}
                  text="DSO"
                  checked={isPrivatePracticeOrDSO === 'dso'}
                />
              </div>
            </div>

            <div>
              <Label text="What is your area of primary practice?" marginBottom={20} />
              <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
                {Object.keys(
                  filterObjectKeys(officeEducation?.specialtiesAndProcedures, 'specialty') || {},
                ).map((code) => (
                  <RadioOption
                    onClick={() => setSelectedSpecialty(code)}
                    text={code.split('_')?.[1]}
                    checked={code === selectedSpecialty}
                  />
                ))}
              </div>
            </div>

            <div>
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <Label
                  text="Select which Dental Association or State this office is affiliated with, if applicable."
                  required={false}
                  marginBottom={20}
                />
                <span
                  style={{
                    marginLeft: '5px',
                    position: 'relative',
                    top: '5px',
                  }}
                >
                  <Tooltip content="GoTu is strategically partnered with various Dental Associations. If you are affiliated with any of the following associations, you may be eligible to have your booking fee refunded on your first completed shift." />
                </span>
              </div>

              {selectedChildOffice?.partnerReferral ? (
                <span style={{ marginLeft: 20 }}>{partnershipOrganization}</span>
              ) : (
                <InputSelect
                  value={partnershipOrganization}
                  setValue={setPartnershipOrganization}
                  options={partnershipOrganizations?.map((item) => ({ title: item, value: item }))}
                />
              )}
            </div>

            {partnershipOrganization && (
              <motion.div
                initial={selectedChildOffice?.partnerReferral ? 'show' : 'hide'}
                animate={partnershipOrganization ? 'show' : 'hide'}
                variants={partnershipCodeVariants}
                transition={{ duration: 0.3 }}
              >
                <Label text="Partner Discount Code:" required={false} marginBottom={20} />

                <div
                  style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  {selectedChildOffice?.partnerReferral ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <span style={{ marginLeft: 20 }}>{partnershipCode}</span>
                      <img
                        src={greenCircleCheck}
                        style={{
                          marginLeft: 10,
                        }}
                        alt="green_circle_check"
                      />
                    </div>
                  ) : (
                    <input
                      className="input-field"
                      type="text"
                      value={partnershipCode}
                      placeholder="Enter Code"
                      onChange={(e) => setPartnershipCode(e.target.value)}
                      disabled={selectedChildOffice?.partnerReferral}
                      style={{
                        width: '100%',
                        maxWidth: '-webkit-fill-available',
                        marginBottom: 0,
                      }}
                    />
                  )}
                </div>

                {!selectedChildOffice?.partnerReferral && (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: 46,
                      marginBottom: 42,
                    }}
                  >
                    <Button
                      type="button"
                      disabled={!partnershipCode}
                      style={{
                        alignSelf: 'center',
                        width: 150,
                        margin: 0,
                        backgroundColor: !partnershipCode
                          ? Colors.secondary_200
                          : Colors.secondary_500,
                      }}
                      onClick={() =>
                        dispatch(
                          verifyPartnershipCode({
                            code: partnershipCode,
                            organization: partnershipOrganization,
                          }),
                        )
                      }
                      text="Apply Code"
                    />
                  </div>
                )}
              </motion.div>
            )}

            <div>
              <Label
                text="Select the expected “time per patient” for each profession"
                required
                marginBottom={20}
              />

              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr 1fr',
                  gap: 26,
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  <span
                    style={{
                      fontFamily: 'Nunito',
                      fontSize: 16,
                      fontWeight: 400,
                      marginBottom: 10,
                      color: Colors.neutral_600,
                    }}
                  >
                    Dental Hygienist:
                  </span>
                  <InputSelect
                    value={timePerPatientRDH}
                    setValue={setTimePerPatientRDH}
                    options={timePerPatientOptions?.map((item) => ({ title: item, value: item }))}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  <span
                    style={{
                      fontFamily: 'Nunito',
                      fontSize: 16,
                      fontWeight: 400,
                      marginBottom: 10,
                      color: Colors.neutral_600,
                    }}
                  >
                    Dental Assistant:
                  </span>
                  <InputSelect
                    value={timePerPatientDA}
                    setValue={setTimePerPatientDA}
                    options={timePerPatientOptions?.map((item) => ({ title: item, value: item }))}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  <span
                    style={{
                      fontFamily: 'Nunito',
                      fontSize: 16,
                      fontWeight: 400,
                      marginBottom: 10,
                      color: Colors.neutral_600,
                    }}
                  >
                    Associate Dentist:
                  </span>
                  <InputSelect
                    value={timePerPatientDN}
                    setValue={setTimePerPatientDN}
                    options={timePerPatientOptions?.map((item) => ({ title: item, value: item }))}
                  />
                </div>
              </div>
            </div>

            <div>
              <Label
                text="Is assisted hygiene available at your office for temp hygienists?"
                marginBottom={20}
                required
              />
              <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
                <RadioOption
                  onClick={() => setIsAssistedHygiene(true)}
                  text="Yes"
                  checked={isAssistedHygiene}
                />
                <RadioOption
                  onClick={() => setIsAssistedHygiene(false)}
                  text="No"
                  checked={isAssistedHygiene === false}
                />
              </div>
            </div>

            <div>
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <Label
                  text="What insurance provider does your office use?"
                  extraText="(Select all that apply)"
                  marginBottom={20}
                />
                <span
                  style={{
                    marginLeft: '5px',
                    position: 'relative',
                    top: '5px',
                  }}
                >
                  <Tooltip content="GoTu now allows you to post temp shifts looking for a Associate Dentist. To be able to post those shifts, you will be required to indicate what insurance your office is covered under. This is to help us find Associate Dentists who are covered under the same insurance as your office." />
                </span>
              </div>
              <MultipleOptions
                selected={insurances}
                onClick={handleInsuranceClick}
                options={officeEducation?.insurances}
              />
            </div>

            <div>
              <Label
                text="What software does your office use?"
                extraText="(Select all that apply)"
                marginBottom={20}
              />
              <MultipleOptions
                selected={softwares}
                onClick={handleSoftwareClick}
                options={translatedSoftwares}
              />
            </div>

            <div>
              <Label text="Notes for Dental Professionals:" required={false} marginBottom={20} />
              <textarea
                className="textarea-style"
                style={{ width: 660 }}
                placeholder="Ex: Check in at the front desk."
                value={notes}
                onChange={(e) => setOfficeNotes(e.target.value)}
              />
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: 100,
                marginTop: 30,
              }}
            >
              <Button
                isLoading={isLoading}
                text={isRegistration ? 'Confirm' : 'Submit'}
                onClick={handleSubmitButton}
                disabled={isSubmitDisabled}
              />
            </div>
          </div>
        </ContentLayout>
      </ContainerLayout>

      {showPartnershipCodeSuccess && (
        <ConfirmPopup
          title="Partner Discount Code Accepted"
          description={`
            Congratulations! As a partner of 
            ${selectedChildOffice?.partnerReferral?.partnershipCode?.organization}, 
            your booking fees will be refunded for your first completed shift on GoTu.`}
          descriptionStyle={{ width: '86%', marginBottom: 50 }}
          rightButtonText="Ok"
          rightButtonAction={() => dispatch(hidePartnershipCodeSuccessPopup())}
          Icon={() => <img src={confirmCircle} style={{ marginBottom: 20 }} alt="confirm_circle" />}
        />
      )}
    </>
  );
};
